import CloseX from 'svg/close-x.svg'
import React from 'react' // eslint-disable-line

import styled from 'styled-components'
export const CartUrgencyWrapper = styled.div`
  &[data-animation='slideDown'] {
    animation: slideDown 1s ease forwards;
  }
  &[data-animation='slideUp'] {
    animation: slideUp 1s ease forwards;
  }

  @keyframes slideDown {
    to {
      top: 0;
    }
  }
  @keyframes slideUp {
    from {
      top: 0;
    }
    to {
      top: -100px;
    }
  }

  // needs to start out of the page and be displayed after the animation
  position: absolute;
  top: -100px;
  display: flex;
  width: 100%;
  height: 60px;
  background-color: #1e1e1e;
  align-items: center;
  justify-content: center;
  z-index: 1100;
  padding: 0px 10px;

  a {
    &[data-style='cart-link'] {
      color: #ffffff;
    }
  }
`
export const CartUrgencyTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 95%;
  gap: 10px;
  [data-style='paragraph-wp'] {
    margin: 0; // in wordpress pages needs to override margin of p
  }
`
export const CartUrgencyCloseIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5%;

  div {
    &[data-style='icon-clickable-div'] {
      cursor: pointer;
      padding: 8px;
    }
  }
`
export const CloseIcon = styled(CloseX)`
  height: 11px;
  width: 11px;
  path {
    stroke: #ffffff;
    stroke-width: 1.5px;
  }
`
