// This is are DEFAULT `saatchi` theme
import React from 'react'
import { ThemeProvider } from 'styled-components'
import { saatchiart } from 'lib/theme'
import GlobalStyle from './GlobalStyle'
type Props = {
  children: React.ReactNode
}

function Theme(props: Props): React.ReactElement<React.ComponentProps<any>, any> {
  const { children } = props
  return (
    <ThemeProvider theme={saatchiart}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  )
}

export default Theme