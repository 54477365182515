import React from 'react'
import SALink from 'components/SALink'
import { Promobar } from './styles'
import { PromoCta } from './Types'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'

type Props = {
  currentCTA: PromoCta
}

const PromoCtaComponent = (props: Props): React.ReactElement<React.ComponentProps<any>, any> => {
  const { currentCTA } = props
  const { color, dataLayerEvent, linkText, linkURL, mobileTagline, tagline } = currentCTA || {}

  const onClickPromoCta = () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: dataLayerEvent || 'cta promo bar',
      })
    }
  }

  return (
    <Promobar data-section='promo_bar' background={color}>
      <div className='wrapper'>
        <div className='mobile'>
          <SATypography
            customClassName='mobile-tagline'
            variant={SATypographyVariant.CTA_SMALL}
            data-style='paragraph-wp'
          >
            {mobileTagline}
          </SATypography>

          <SATypography
            customClassName='link'
            variant={SATypographyVariant.CTA_SMALL}
            data-style='paragraph-wp'
          >
            <SALink href={linkURL} onClick={onClickPromoCta}>
              {linkText}
            </SALink>
          </SATypography>
        </div>
        <div className='layout'>
          <SATypography
            customClassName='tagline'
            variant={SATypographyVariant.CTA_MEDIUM}
            data-style='paragraph-wp'
          >
            {tagline}
          </SATypography>
          <SATypography
            customClassName='link'
            variant={SATypographyVariant.CTA_MEDIUM}
            data-style='paragraph-wp'
          >
            <SALink href={linkURL} onClick={onClickPromoCta}>
              {linkText}
            </SALink>
          </SATypography>
        </div>
      </div>
    </Promobar>
  )
}

export default PromoCtaComponent
