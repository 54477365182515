import React, { PropsWithChildren } from 'react'
import dynamic from 'next/dynamic'
import { ConnectorProps as LayoutProps } from 'containers/Layout'
import LayoutConnector from 'containers/Layout'
import StyleBase from 'components/StyleBase'
import Loader from 'components/Loader'
import PromoBar from 'components/Promobar'
import { AppLayout } from 'styles/layout'
import LogStore from 'components/LogStore'
import CartUrgencyBar from 'components/CartUrgencyBar'

const Header = dynamic(
  import(
    /* webpackChunkName: "navigation" */
    'components/Header'
  )
)
const Footer2 = dynamic(
  import(
    /* webpackChunkName: "navigation" */
    'components/Footer/v2'
  )
)
const { APP_ENV } = process.env
type Props = LayoutProps & {
  disableHeader?: boolean
  disableFooter?: boolean
  siteContext?: string
  dataApp: string // pass in app name for STYLING overrides
}

const NavigationLayout = (props: PropsWithChildren<Props>) => {
  const {
    children,
    dataApp,
    disableFooter,
    disableHeader,
    iOSWebview,
    isReady,
    siteContext,
  } = props

  if (iOSWebview) {
    return (
      <StyleBase>
        <AppLayout
          data-app={dataApp || 'app'}
          data-sitecontext={siteContext}
          {...(!isReady
            ? {
                [`data-loading`]: isReady,
              }
            : {})}
        >
          <Loader />

          {children}
        </AppLayout>
      </StyleBase>
    )
  }

  return (
    <StyleBase>
      <div>
        <CartUrgencyBar />
        <PromoBar />
      </div>
      {!disableHeader && <Header siteContext={siteContext} />}

      <AppLayout
        data-app={dataApp || 'app'}
        data-sitecontext={siteContext}
        {...(!isReady
          ? {
              [`data-loading`]: isReady,
            }
          : {})}
      >
        <Loader />

        {children}
      </AppLayout>

      {!disableFooter && <Footer2 />}

      {/* Development Logger for LOCAL only */}
      {APP_ENV === 'local' && <LogStore />}
    </StyleBase>
  )
}

export default LayoutConnector(NavigationLayout)
