import type {
  SATypographyComponent,
  GetComponentByVariantParams,
} from "./SATypography.model";
import { SATypographyVariant } from "./SATypography.model";

export function getComponentByVariant({
  variant,
  component,
}: GetComponentByVariantParams): SATypographyComponent {
  if (typeof component === "string") {
    return component;
  }

  switch (variant) {
    case SATypographyVariant.H1: {
      return "h1";
    }

    case SATypographyVariant.H2: {
      return "h2";
    }

    case SATypographyVariant.H3: {
      return "h3";
    }

    case SATypographyVariant.H4: {
      return "h4";
    }

    case SATypographyVariant.H5: {
      return "h5";
    }

    case SATypographyVariant.H6: {
      return "h6";
    }

    case SATypographyVariant.X_LARGE:
    case SATypographyVariant.LARGE:
    case SATypographyVariant.MEDIUM:
    case SATypographyVariant.SMALL:
    case SATypographyVariant.TINY:
    case SATypographyVariant.CTA_X_LARGE:
    case SATypographyVariant.CTA_LARGE:
    case SATypographyVariant.CTA_MEDIUM:
    case SATypographyVariant.CTA_SMALL:
    default: {
      return "p";
    }
  }
}

export const MOCK_TEST_VARIANT_COMPONENT = [
  {
    variant: SATypographyVariant.H1,
    expected: "h1",
  },
  {
    variant: SATypographyVariant.H2,
    expected: "h2",
  },
  {
    variant: SATypographyVariant.H3,
    expected: "h3",
  },
  {
    variant: SATypographyVariant.H4,
    expected: "h4",
  },
  {
    variant: SATypographyVariant.H5,
    expected: "h5",
  },
  {
    variant: SATypographyVariant.H6,
    expected: "h6",
  },
  {
    variant: SATypographyVariant.X_LARGE,
    expected: "p",
  },
  {
    variant: SATypographyVariant.LARGE,
    expected: "p",
  },
  {
    variant: SATypographyVariant.MEDIUM,
    expected: "p",
  },
  {
    variant: SATypographyVariant.SMALL,
    expected: "p",
  },
  {
    variant: SATypographyVariant.X_SMALL,
    expected: "p",
  },
  {
    variant: SATypographyVariant.TINY,
    expected: "p",
  },
  {
    variant: SATypographyVariant.CTA_X_LARGE,
    expected: "p",
  },
  {
    variant: SATypographyVariant.CTA_LARGE,
    expected: "p",
  },
  {
    variant: SATypographyVariant.CTA_MEDIUM,
    expected: "p",
  },
  {
    variant: SATypographyVariant.CTA_SMALL,
    expected: "p",
  },
];

export const capitalizeText = (string: string): string =>
  `${string || ''}`.toLowerCase().replace(/^(.)|[\s|-]+(.)/g, ($1) => $1.toUpperCase()) || ''
