import React, { useMemo } from 'react'
import { useCarousel } from 'hooks/carousel'
import Carousel from 'components/Carousel'
import valueProps from './data'
import { Dots } from '../styles'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import useScreenSize from 'hooks/useScreenSize'

export default (): React.ReactElement<React.ComponentProps<any>, any> => {
  const { next, prev, index, setIndex } = useCarousel(valueProps.length)
  const { isMobile } = useScreenSize()

  const desktopCarouselStyles = useMemo(
    () => ({
      display: 'flex',
      gap: '64px',
      padding: '0px 64px',
    }),
    []
  )

  const mobileCarouselStyles = useMemo(
    () => ({
      display: 'flex',
      width: '300%',
    }),
    []
  )

  return (
    <div data-type='valuePropWrapperMobile'>
      <Carousel
        onNext={next}
        onPrev={prev}
        setIndex={setIndex}
        index={index}
        carouselName='valuePropsCarousel'
        columns={1}
        carouselStyles={isMobile ? mobileCarouselStyles : desktopCarouselStyles}
      >
        {valueProps.map((valueProp, key) => (
          <div key={key} data-type='valuePropItem'>
            {valueProp.icon}
            <div data-type='value-prop-text-container'>
              <SATypography data-type='value-prop-item-title' variant={SATypographyVariant.H5}>
                {valueProp.title}
              </SATypography>
              <SATypography
                data-type='value-prop-item-description'
                variant={SATypographyVariant.X_SMALL}
              >
                {valueProp.description}
              </SATypography>
            </div>
          </div>
        ))}
      </Carousel>
      <Dots
        data-type='valuePropsDots'
        index={index}
        length={valueProps.length}
        setIndex={setIndex}
      />
    </div>
  )
}
