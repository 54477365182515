import React from 'react'
import SALink from 'components/SALink'
import { SATypography } from 'components/SATypography/SATypography'
import { SATypographyVariant } from 'components/SATypography/SATypography.model'
import useScreenSize from 'hooks/useScreenSize'

const ArtAdvisoryCallout = (): React.ReactElement<React.ComponentProps<any>, any> => {
  const { isMobile } = useScreenSize()
  const imageBase = 'https://d3t95n9c6zzriw.cloudfront.net/homepage/2020/aa/Hybrid-Homepage-RW-Prog'

  return (
    <div data-type='artAdvisoryWrapper'>
      <div data-type='art-advisory-content'>
        <picture data-type='image'>
          <source
            type='image/jpg'
            loading='lazy'
            media='(max-width:980px)'
            srcSet={`${imageBase}-small.jpg`}
          />
          <img
            className='lazyload'
            loading='lazy'
            width='922'
            height='693'
            data-src={`${imageBase}-large.jpg`}
            alt='Rebecca Wilson'
            itemProp='image'
          />
        </picture>
        <noscript>
          <img
            width='922'
            height='693'
            src={`${imageBase}-large.jpg`}
            alt='Rebecca Wilson'
            itemProp='image'
          />
        </noscript>
        <div data-type='callout'>
          {isMobile ? (
            <>
              <SATypography data-type='mobile-pre-cta' variant={SATypographyVariant.H3}>
                Find Art You Love
              </SATypography>
              <SALink href='https://www.saatchiart.com/artadvisory'>
                <SATypography
                  data-type='callout-cta-text'
                  variant={
                    isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM
                  }
                >
                  WORK WITH AN ART ADVISOR
                </SATypography>
              </SALink>
            </>
          ) : (
            <>
              <SATypography data-type='desk-header' variant={SATypographyVariant.H3}>
                Find Art You Love
              </SATypography>
              <div data-type='callout-info-container'>
                <SATypography data-type='copy' variant={SATypographyVariant.SMALL}>
                  “At Saatchi Art, we make it our mission to help you discover and buy from the best
                  emerging artists around the world. Whether you’re looking to discover a new
                  artist, add a statement piece to your home, or commemorate an important life
                  event, Saatchi Art is your portal to thousands of original works by today’s top
                  artists.”
                </SATypography>
                <img
                  data-type='signature'
                  width='220'
                  height='40'
                  src='https://d3t95n9c6zzriw.cloudfront.net/homepage/2020/aa/w-sig-black.png'
                  alt="Rebecca Wilson's Signature"
                  loading='lazy'
                />
                <SATypography data-type='credit' variant={SATypographyVariant.H6}>
                  Chief Curator & VP, Art Advisory
                </SATypography>
                <SALink href='https://www.saatchiart.com/artadvisory'>
                  <SATypography
                    data-type='callout-cta-text'
                    variant={
                      isMobile ? SATypographyVariant.CTA_SMALL : SATypographyVariant.CTA_MEDIUM
                    }
                  >
                    WORK WITH AN ART ADVISOR
                  </SATypography>
                </SALink>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default ArtAdvisoryCallout
