import React, { PropsWithChildren, FC } from 'react'
import { SATypographyColor, SATypographyProps } from './SATypography.model'
import { SATypographyVariant } from './SATypography.model'
import styles from './SATypography.module.scss'
import { capitalizeText, getComponentByVariant } from './helpers'
import classNames from 'classnames'

export const SATypography: FC<PropsWithChildren<SATypographyProps>> = ({
  name = 'satypography',
  children,
  component,
  customClassName = '',
  customDivClassName = '',
  variant = SATypographyVariant.SMALL,
  truncateText,
  bold,
  capitalize,
  uppercase,
  textCenter,
  color = SATypographyColor.UNSET,
  ...props
}) => {
  const Component = getComponentByVariant({ variant, component })
  const isChildrenString = typeof children === 'string'

  return (
    <div
      data-type={name}
      className={classNames({ [customDivClassName]: customDivClassName !== '' })}
    >
      <Component
        className={classNames(styles[variant], {
          [styles.bold_text]: bold,
          [styles.truncate_text]: truncateText,
          [styles.uppercase_text]: uppercase,
          [styles.text_align_center]: textCenter,
          ...(color ? { [styles[color]]: true } : {}),
          [customClassName]: customClassName !== '',
        })}
        {...props}
      >
        {capitalize && isChildrenString ? capitalizeText(children) : children}
      </Component>
    </div>
  )
}
