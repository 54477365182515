import { ReactNode } from 'react'

export type SATypographyComponent = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span'

export enum SATypographyVariant {
  X_LARGE = 'SATypography__xlarge',
  LARGE = 'SATypography__large',
  MEDIUM = 'SATypography__medium',
  SMALL = 'SATypography__small',
  X_SMALL = 'SATypography__xsmall',
  TINY = 'SATypography__tiny',
  H1 = 'SATypography__h1',
  H2 = 'SATypography__h2',
  H3 = 'SATypography__h3',
  H4 = 'SATypography__h4',
  H5 = 'SATypography__h5',
  H6 = 'SATypography__h6',
  CTA_X_LARGE = 'SATypography__cta__xlarge',
  CTA_LARGE = 'SATypography__cta__large',
  CTA_MEDIUM = 'SATypography__cta__medium',
  CTA_SMALL = 'SATypography__cta__small',
}

export enum SATypographyColor {
  UNSET = '',
  GUNMETAL = 'color_gunmetal',
  WHITE = 'color_white',
  ERROR = 'color_error',
}

export interface SATypographyProps {
  readonly name?: string
  readonly children: string | number | ReactNode
  readonly component?: SATypographyComponent
  readonly variant?: SATypographyVariant
  readonly customDivClassName?: string
  readonly customClassName?: string
  readonly color?: SATypographyColor
  readonly truncateText?: boolean
  readonly bold?: boolean
  readonly capitalize?: boolean
  readonly uppercase?: boolean
  readonly textCenter?: boolean
  readonly [key: string]: unknown
}

export interface GetComponentByVariantParams {
  readonly variant: SATypographyVariant
  readonly component?: SATypographyComponent
}
